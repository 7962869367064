function initSlider() {
  const sliderElement = document.querySelector(".slider");
  if (!sliderElement) {
    return;
  }
  document.querySelectorAll(".slider__item").length;
  const { sliderLength } = sliderElement.dataset;
  const counterValueElement = document.querySelector(".slider__counter__value");
  const initialCounterValue = getComputedStyle(sliderElement).getPropertyValue(
    "--counter"
  );
  let counterValue = parseInt(initialCounterValue);
  const dots = document.querySelectorAll(".dot");

  const nextElement = document.querySelector(".slider__next");
  const prevElement = document.querySelector(".slider__prev");
  function updateCounter(counterValue) {
    sliderElement.style.setProperty("--counter", counterValue);
    counterValueElement.innerText = counterValue + 1;

    const activeDotElement = document.querySelector(".dot.active");
    activeDotElement.classList.remove("active");

    const nextActiveDotElement = dots[counterValue];
    nextActiveDotElement.classList.add("active");
  }

  function handleChange(direction) {
    const newCounterValue = direction + counterValue;

    if (sliderLength && newCounterValue === parseInt(sliderLength)) {
      counterValue = 0;
    } else if (newCounterValue >= 0) {
      counterValue = newCounterValue;
    }
    updateCounter(counterValue);
  }

  dots.forEach((dot) =>
    dot.addEventListener("click", () => {
      // do something
      const nextCounterValue = parseInt(dot.dataset.index);
      updateCounter(nextCounterValue);
    })
  );

  nextElement.addEventListener("click", () => {
    handleChange(1);
  });

  prevElement.addEventListener("click", () => {
    handleChange(-1);
  });
}

initSlider();
