const subheaderEl = document.querySelector(".content__subheader");
const contentEl = document.querySelector(".content");
const dropsEl = document.querySelectorAll(".drop")

const triggerTop = contentEl.offsetTop;

function handleScroll(scrollPosition) {
  if (scrollPosition >= triggerTop) {
    subheaderEl.classList.add("content__subheader--scrolled");
  } else if (scrollPosition < triggerTop) {
    subheaderEl.classList.remove("content__subheader--scrolled");
  }

  updateDrop(scrollPosition)
}


function updateDrop(scrollValue) {
  dropsEl.forEach((el) => {
    el.style.setProperty("--scroll-value", `-${scrollValue/50}`);
  });
}


let lastKnownScrollPosition = 0;
let horizontalScroll = 0;
let ticking = false;

window.addEventListener("scroll", () => {
  lastKnownScrollPosition = window.scrollY;
  if (ticking) {
    window.requestAnimationFrame(function () {
      handleScroll(lastKnownScrollPosition);
      ticking = false;
    });
  }

  ticking = true;
});
