import "normalize.css";
import "./css/style.scss";

import "./css/header.scss";
import "./css/footer.scss";
import "./css/section.scss";
import "./css/content.scss";
import "./css/contact.scss";
import "./css/catchphrase.scss";
import "./css/discover.scss";
import "./css/map.scss";
import "./css/slider.scss";
import "./css/workpage.scss";

import "./css/blocks/service-block.scss";
import "./css/blocks/picto-block.scss";
import "./css/blocks/bullet-block.scss";
import "./css/blocks/intro-block.scss";
import "./css/blocks/flowchart-block.scss";
import "./css/blocks/highlight-block.scss";
import "./css/blocks/work-block.scss";

import "./js/slider";
import "./js/burger-menu";
import "./js/scroll";
